@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /*font-family: uri("https://use.typekit.net/jav0cbv.css");*/
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: capitalize;
  font-family: proxima-nova, sans-serif !important;
  font-style: normal;
  /* font-weight: 100; */
}

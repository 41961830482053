@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
body{
  font-family: 'Fira Sans', sans-serif;
}
.main-form {
  line-height: 10px;
  margin-top: 20px;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  padding: 30px 40px !important;
}

.swal-text {
  text-transform: none;
}

/* Center items vertically */
.vcenter-item {
  display: flex;
  align-items: center;
}

/* Radio button style */
.radiobtn {
  position: relative;
  /* display: block;
  text-align: center;
  line-height: 44px;
  border: 1px solid #c0c0c0; */
}

.radiobtn label {
  display: block;
  background: #fff;
  padding: 10px 16px;
  margin-bottom: 0;
  height: 64px;
  cursor: pointer;
  font-weight: 400!important;
  font-size: 16px!important;
  /* border: 1px solid #C8E3F5; */
  border: 1px solid #caf8e4;
  border-radius:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]{
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;
}

input[type="radio"]:checked + label {
  background: linear-gradient(245.05deg, #30BE7F 0%, #1BB872 83.02%);
    /* background: linear-gradient(245.05deg, #5ABEFF 0%, #079DFF 83.02%); */
  border-color:#30BE7F;
  color: #fff;
}

.txt-box {
  width: 80px !important;
}

.custom-input label {
  display: inline !important;
  vertical-align: top !important;
}

.custom-input input[type="radio"]:checked + label {
  background-color: transparent !important;
  color: #000;
}

/* Checkbox style */

.chkbox {
  background: #fff;
  /*border-radius: 5px;*/
  border: 1px solid #d0d0d0;
  /*width: 85px;*/
  height: 64px;
  line-height: 15px;
}

.chkbox label,
span {
  text-align: center;
  width: 100%;
  height: inherit;
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  white-space: nowrap;
}

.chkbox label input {
  display: none;
}
.chkbox input:checked + span {
  background-color: #3979d5;
  color: #fff;
}
.slider_col {
  text-align: center;
  color: #3A4162;
  font-size:15px;
  font-weight:400;
  max-width:156px!important;
}
.commonLabel {
  padding: 10px 10px 0 10px !important;
}

.brt {
  padding: 0 15px;
}



/** FF*/
/* input[type="range"]::-moz-range-progress {
 background-color: #23a16b;
} */
/* input[type="range"]::-moz-range-track {
 background-color: #23a16b;
} */
/*!* IE*!*/
/* input[type="range"]::-ms-fill-lower {
 background-color: #23a16b;
} */
/* input[type="range"]::-ms-fill-upper {
 background-color: #23a16b;
} */


.rangeslider{height:10px!important;
  box-shadow:none!important;
  background: #def4ea!important;
}
.rangeslider-horizontal .rangeslider__handle:after{display:none;}
.rangeslider-horizontal .rangeslider__handle{
  width: 25px!important;
  height: 25px!important;
  border: 2px solid #2abc7b!important;
  box-shadow:none!important;
}
.rangeslider-horizontal .rangeslider__fill{
  background: linear-gradient(245.05deg, #25d385 0%, #2abc7b 83.02%);
  box-shadow:none!important;
}

.custom_progress{
  position: relative;height:10px;
  max-width: 850px;
  width: 100%;
}
.custom_progress .progress{
  border-radius: 10px!important;
  height: 10px!important;
  background-color: #E9F6FF!important;
  position: relative;
}
.custom_progress .progress-bar{
  background: linear-gradient(245.05deg, #5ABEFF 0%, #079DFF 83.02%);
}
.custom_progress .value_control{
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #079DFF;
  cursor: pointer;
  transition: .3s ease-in-out;
  position: absolute;
  z-index: 1;
  top: -7px;
}
.banner-style{
  width: 100%;
  padding-top: 105px;
  padding-bottom: 0px;
}
.cust-navbar {
  background-color: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link{
    color: #000000 !important;
    font-family: 'Fira Sans', sans-serif;
    font-size:16px;font-weight:400;
    text-transform: initial;
}
.navbar-light .navbar-nav .nav-link:hover{
    transition: all 0.3s; 
    color:#2BA06C!important;
}
.header li{margin-right:20px;}
.header li:last-child{margin-right:0px;}
.header li.brandlogo{margin:0px 100px;}
.crossSection h4{
    color: #000000;
    font-family: 'Fira Sans', sans-serif;
    font-size:24px;
    font-weight:400;
    margin-bottom:50px;
}
.crossSection .card{
  margin-bottom:-3px;
  padding:7px 40px;
}
.crossSection .card:before{
  content:'';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2BA06C;
  position: absolute;
  left: 0;
  top: 0;

}
.crossSection .card:after{
  content:'';
  position: absolute;
  top: 0;
  height: 100%;
  background: #2BA06C;
  width: 2px;
  left: 6px;
}
.crossSection .card label{
   color:#3A4162;
   font-size:18px;
   font-weight:400;
   font-family: 'Fira Sans', sans-serif;
}
.streetcontentForm{
    border: 1px solid #f5f5f5;
    padding: 20px;
    border-radius: 10px;
}
.crossSection-btn{
  background: linear-gradient(245.05deg, #30BE7F 0%, #1BB872 83.02%);
  border-radius: 10px!important;
  font-family: 'Fira Sans', sans-serif;
  font-size:20px!important;
  font-weight:500!important;
  border: 0px!important;
  outline: 0px!important;
  padding: 17px 20px!important;
  max-width:300px;
}
.crossbtnRow:before{
    top:30px!important;
}
.crossbtnRow:after{
  left: 7px!important;
  height: 40px!Important;
}
.regularText{
  color:#3A4162;
  font-size:14px;
  font-weight:400;
  font-family: 'Fira Sans', sans-serif;
}
.boldText{
  font-size:18px;font-weight:700;
  font-family: 'Fira Sans', sans-serif;
}
.mediumText{
  font-size:16px;font-weight:600;
  font-family: 'Fira Sans', sans-serif;
}
.lineHeight21{
  line-height:21px;
}
.bg-danger-light{
  background: #FFEEEE;
}
.bg-success-light{
  background: #a4ffb9;
}
.carriageLane .slider_col {
  max-width:207px!important;
}

/*footer css*/
.footer{ 
  background:#1F1F1F;
  padding:53px 0px 47px;
  font-family: 'Fira Sans', sans-serif;
}
.footer ul li{
  display:inline-block;
  border-right: 1px solid white;
  line-height: 0px;
}
.footer ul li:last-child{
  border:0px;
}
.footer ul li a{
  color:#fff;
  font-size:14px;
  text-transform:initial;
}
.footer ul li a:hover{ 
  transition: all 0.3s; 
  color:#2BA06C;
}
.socialIcon{
  justify-content: flex-end;
}
.socialIcon li{
  border:0px!important;
}
.socialIcon li a{
  padding:0px 15px;
}
.socialIcon svg{ 
  filter: brightness(0)invert(1);
}
.socialIcon li a:hover svg{ 
  transition: all 0.3s; 
  filter:none;
}
.valuebtn_outer{
   display: flex;
   align-items: center;
   justify-content:flex-end;
}
.valueBtn{
   flex-wrap:unset!important;
   width:140px!important;
   color:#3A4162;
   background: #FFFFFF;
   border: 1px solid #DEDEDE;
   border-radius: 20px;
}
.valueBtn .input-group-text{
  width:40px;
  height:40px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border:0px;
  cursor: pointer;
  padding:0px;
}
.valueBtn input{
    height:40px;
    border:0px;
    font-size:16px;
    font-weight: 700;
    color:#3A4162;
    border: 1px solid #DEDEDE;
    border-top:0px;
    border-bottom:0px;
    text-align: center;
    box-shadow: none!important;
    padding:0px;
  }
.input-group>input.form-control{
  min-width: 60px;
}
.valueBtn input::placeholder{
  color:#3A4162;
}
.meter-label {
    text-transform: lowercase;
    padding-left: 10px;
    color:#3A4162;
    font-size:16px;
    font-weight:600;
}
.totalValue input{
   color:#2BA06C;
   margin-left: -15px;
}
.totalValue input::placeholder{
   color:#2BA06C;
}
.popover{
  background: #FFFFFF!important;
  border: 1px solid #2BA06C!important;
  border-radius: 15px!important;
  font-family: 'Fira Sans'!important;
  font-style: normal;
  font-weight: 400!important;
  font-size: 15px!important;
  color: #3A4162!important;
  max-width: 400px!important;
}
.popover-body{
  padding: 20px!important;
  display: flex;
  flex-direction: row-reverse;
  /* background-color: #47e99d94; */
  background: linear-gradient(245.05deg, #30bf7f33 0%, #1bb87238 83.02%);
  border-radius: 13px;
}
.popover-body svg{
  order:1;
  width: 60px;
  height: 40px;
  margin-right: 15px;
  /* color:#2bb876 !important; */
  color:#1e744e!important;
  min-width: 30px;
}
.bs-popover-auto[x-placement^=top]>.arrow::after, .bs-popover-top>.arrow::after {
  border-top-color: #d0f0e2 !important;
}
.bs-popover-auto[x-placement^=top]>.arrow::before, .bs-popover-top>.arrow::before{
  border-top-color:#2BA06C!important;
}
html,body{
  overflow-x: hidden;
}
.statictext_row {
  position: relative;
}
.statictext_row  .statictext{
  padding-right: 70px;
} 
.statictext_row .staticValue{
  position: absolute;
  right:64px;
}

@media screen and (max-width:1680px){
  .streetcontentForm .wrapper.w-75{width:100%!important;}
  .valueBtn{width:120px!important;}
  .statictext_row .statictext{ white-space: normal;text-align: left;}
  .statictext_row{font-size: 13px;}
  .aftrtext{display: flex;justify-content: center;align-items: center;}
  .statictext_row .statictext {padding-right: 30px;line-height: normal;}
  .statictext_row .staticValue {position: relative; right: inherit;}
  .valueBtn .input-group-text svg{width:15px ;}
}

@media screen and (max-width:1199px){
  .header li.brandlogo {margin: 0px 20px;}
  .header li { margin-right: 10px;}
  .valueBtn{width:70px!important;}
  .input-group>input.form-control {
    min-width: 40px;}
  .valueBlock .valuebtn_outer{
    height: 40px;
  }
  .meter-label, .valueBtn input{
    font-size: 12px;
  }
}

@media ( max-width:1379px){
  .radiobtn label{
    padding: 10px 5px;  
    font-size: 10px!important;
    height: 55px;
  }
}
 @media(max-width: 1199px ) {
   .radiobtn label{
    padding: 10px 5px;  
    font-size: 10px!important;
    height: 45px;
  }
  
 }


@media screen and (max-width:991px){
  .main-form { padding-left: 15px !important; padding-right: 15px !important;}
  .crossSection .card {padding: 0 0 0 30px}
  .footer{padding:24px 0px 20px;}
  .footer ul{justify-content:center;}
  .socialIcon{margin-top:20px;}
  .navbar-light .navbar-nav .nav-link{font-size:14px;}
  .navbar-brand{width:100%; max-width:30px;}
  .banner-style{padding-top: 55px; min-height: 160px;}
  .rangelBlock{ margin-bottom: 2em;}
  .streetcontentForm .wrapper.w-75{ flex-direction: row; margin-top: 1em;}
  .valueBlock{flex-direction: column; flex: none;display: flex;justify-content: center;align-items: center;}
  .valuebtn_outer{display: flex;justify-content: center;}
  .statictext_row .staticValue{ position: relative; right: inherit;display: flex;justify-content: center;align-items: center;}
  .aftrtext{display: flex;justify-content: center;}
  .crossSection .card label{ line-height: normal;}
  .statictext_row .statictext{line-height: normal;}
}
@media screen and (max-width:767px){
   .header{ padding:8px 30px!important;}
   .brandlogo{display: none;}
   .popover.show.bs-popover-auto {   width: 78%;}
}


.icon-small svg{
  width: 24px;
  height: 24px;
}
/* new css foe switch */
/* Switch Specific Style Start */

.box_4{
	background: #eee;
}

.input_wrapper{
  /* width: 80px; */
  height: 34px;
  position: relative;
  cursor: pointer;
}

.input_wrapper input[type="checkbox"]{
  width: 44px;
  /* width: 80px; */
  height: 40px;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* background: #315e7f; */
  border-radius: 2px;
  position: relative;
  outline: 0;
  /* -webkit-transition: all .2s; */
  /* transition: all .2s; */
}

.input_wrapper input[type="checkbox"]:after{
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 34px;
  height: 34px;
  /* background: #dfeaec; */
  z-index: 2;
  border-radius: 2px;
  /* -webkit-transition: all .35s; */
  /* transition: all .35s; */
}

.input_wrapper svg{
  position: absolute;
  top: 7%;
  /* -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; */
  fill: #fff;
  /* -webkit-transition: all .35s; */
  /* transition: all .35s; */
  z-index: 1;
  width: 25px;
  right: 45px;
}

.input_wrapper .is_checked{
  /* width: 25px; */
  width: 34px;
  left: 11%;
  /* -webkit-transform: translateX(190%) translateY(-30%) scale(0);
          transform: translateX(190%) translateY(-30%) scale(0); */
}

.input_wrapper .is_unchecked{
  /* width: 25px; */
  width: 34px;
  right: 11%;
  /* -webkit-transform: translateX(0) translateY(-30%) scale(1);
          transform: translateX(0) translateY(-30%) scale(1); */
}

/* Checked State */
.input_wrapper input[type="checkbox"]:checked{
  /* background: #30BE7F; */
  /* background: #315e7f; */
}

.input_wrapper input[type="checkbox"]:checked:after{
  left: calc(100% - 37px);
}

.input_wrapper input[type="checkbox"]:checked + .is_checked{
  /* -webkit-transform: translateX(0) translateY(-30%) scale(1);
          transform: translateX(0) translateY(-30%) scale(1); */
}

.input_wrapper input[type="checkbox"]:checked ~ .is_unchecked{
  /* -webkit-transform: translateX(-190%) translateY(-30%) scale(0);
          transform: translateX(-190%) translateY(-30%) scale(0); */
}

/* Switch Specific Style End */

/* Css for language change  Start */
/* .body_lang_change .custom_progress{    max-width: 400px;} */

/* @media screen and (min-width:992px) and (max-width:1559px){
  
  .body_lang_change .regularText{
    font-size: 9px;
    margin-right: 10px;
  }
  .body_lang_change .input-group{
height: 30px;
  }
  .body_lang_change .input-group>input.form-control{
    height: 28px;
  }
  .body_lang_change .valueBtn .input-group-text{
    height: 30px;

  }
  .body_lang_change .meter-label{
    padding-left: 5px;
  }
  label{
    font-size: 12px;
  }
  
} */

/* Css for language change  End */
